<template>
  <div class="notification_dialog_content" ref="notificationDialog">
    <template>
      <h2>小时超标次数表</h2>
      <el-date-picker
        v-model="oneDay"
        type="date"
        value-format="yyyy-MM-dd"
        @change="dayChange"
      >
      </el-date-picker>
      <ve-histogram
        :data="showData.dataOption"
        :colors="['#00A1FF']"
        :settings="chartSettings"
        :grid="grid"
        :data-empty="showData.dataOption.rows.length === 0"
      />
    </template>
    <div class="chart-line">
      <h2>日超标次数表</h2>
      <el-date-picker
        v-model="peorid"
        type="daterange"
        value-format="yyyy-MM-dd"
        @change="monthChange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
      <e-charts :options="lineData" autoresize />
    </div>
  </div>
</template>
<script>
import "v-charts/lib/style.css";
import VeHistogram from "v-charts/lib/histogram.common";
import ECharts from "vue-echarts";
import "echarts/lib/chart/line";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/markLine";
import "echarts/lib/component/legend";

export default {
  props: ["localeId", "owner"],
  components: { VeHistogram, ECharts },
  data() {
    return {
      oneDay: "",
      peorid: [],
      dataOption: {
        columns: ["时间", "次数"],
        rows: []
      },
      chartSettings: {
        // metrics: ["次数"],
        dimension: ["时间"],
        // xAxisType: 'time',
        yAxisName: ["次数"]
      },
      grid: {
        show: true,
        top: 50,
        left: 10
        // backgroundColor: '#404a59'
      },
      lineData: {
        tooltip: {
          trigger: "axis"
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          data: [],
          boundaryGap: false,
          axisLabel: {
            interval: 1,
            rotate: 45
          }
        },
        yAxis: {
          type: "value",
          // min: 5,
          // data : [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4,5],
          name: "次数"
        },
        series: [
          {
            name: "次数",
            type: "line",
            data: [],
            itemStyle: {
              normal: {
                color: "#00A1FF",
                lineStyle: {
                  type: "solid",
                  color: "#00A1FF"
                }
              }
            }
          }
        ]
      },
      showData: {
        dataOption: {
          columns: ["时间", "次数"],
          rows: []
        }
      }
    };
  },
  mounted() {
    const last1day = this.getNextDate(-1);
    const last30day = this.getNextDate(-30);
    this.getHoursDatas(this.localeId, this.owner, last1day)
    this.getMonthData(this.localeId, this.owner, last30day, last1day)
  },
  watch: {
    showData: v => {
      // console.log(v);
    }
  },
  methods: {
    setDefault() {
      this.lineData.xAxis.data = []
      this.lineData.series[0].data = []
      this.showData = {
        dataOption: {
          columns: ['时间', '次数'],
          rows: []
        }
      }
    },
    getNextDate(day) {
      const dd = new Date()
      dd.setDate(dd.getDate() + day)
      const y = dd.getFullYear()
      const m =
        dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1
      const d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate()
      return y + '-' + m + '-' + d
    },
    /**
     * @description 关闭对话框
     */
    handleClose() {
      this.$emit("on-close", false);
    },
    getHoursDatas(localeId, owner, date) {
      const param = {
        LocaleId: localeId,
        Owner: owner,
        AcquitDate: date
      };
      this.$post(
        "admin/queryLocaleData10MinExceedStandardTimesHourly",
        param
      ).then(res => {
        this.showData.dataOption.rows = [];
        res.forEach(v => {
          this.showData.dataOption.rows.push({
            时间: v.AcquitHour.substr(11),
            次数: v.ExceedStandardTimes
          });
        });
      });
    },
    getMonthData(localeId, owner, begin, end) {
      const param = {
        LocaleId: localeId,
        Owner: owner,
        AcquitBeginDate: begin,
        AcquitEndDate: end
      };
      this.$post(
        "admin/queryLocaleData10MinExceedStandardTimesDaily",
        param
      ).then(res => {
        this.lineData.xAxis.data = res.map(v => {
          return v.AcquitDate;
        });
        this.lineData.series[0].data = res.map(v => {
          return v.ExceedStandardTimes;
        });
      });
    },
    dayChange() {
      this.getHoursDatas(this.localeId, this.owner, this.oneDay);
    },
    monthChange() {
      this.getMonthData(
        this.localeId,
        this.owner,
        this.peorid[0],
        this.peorid[1]
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.checkbox_group_box {
  font-size: inherit;
  .el-checkbox {
    display: block;
  }
}
.notification_dialog_content {
  font-size: 13px;
  .el-form-item {
    margin-bottom: 0;
    .el-form-item__content {
      span {
        line-height: 40px;
      }
    }
  }
  h2 {
    text-align: center;
  }
  h4 {
    font-size: 14px;
    margin: 10px 0 5px;
  }

  h5 {
    font-size: 13px;
    margin: 5px 0;
  }

  p {
    margin: 5px 0;
  }

  .date_box {
    text-align: right;
    padding: 10px 0;
    margin-top: 20px;
    font-weight: bold;
    font-weight: 15px;
  }
}
</style>
<style>
.echarts {
  width: 100% !important;
}
</style>
