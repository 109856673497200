<template>
  <div>
    <el-dialog
      :title="mode==='view'?'查看':'处理'"
      :visible.sync="visible"
      width="40%"
      :before-close="handleClose">
      <div class="block">
        <el-timeline>
          <el-timeline-item timestamp="材料证明" placement="top">
            <el-card>
              <el-image v-if="info.pics !=''" v-for="item in fileList" :key="item" :src="item" :preview-src-list="fileList" style="width: 100px;height: 100px" />
              <p>原因: {{ info.reason }}</p>
              <p>申请时间 {{info.create_at}}</p>
            </el-card>
          </el-timeline-item>
          <template v-if="info.type == 2">
            <el-timeline-item timestamp="管理单位审核结果" placement="top">
              <el-card>
                <h4><template v-if="adminStatus == 0"><span style="color:#BABABA;">待审核</span></template><template v-else-if="adminStatus == 1"><span style="color:#8BBFFD;">通过</span></template><template v-else><span style="color:#6F7073;">未通过</span></template></h4>
                <template v-if = "adminStatus > 0">
                  <p>备注: {{adminRemark}}</p>
                  <p>审核时间 {{admin_create_at}}</p>
                </template>
                <template v-else>
                  <el-form label-width="80px" style="margin-top: 20px" label-position="left" v-if="mode=== 'edit' && adminStatus === 0">
                    <el-form-item label="审核结果">
                      <el-select
                        v-model="editStatus">
                        <el-option :value="1" :label="'通过'"></el-option>
                        <el-option :value="2" :label="'未通过'"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="备注">
                      <el-input v-model="editRemark" placeholder="请输入备注信息"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-button type="primary" @click="submit">提交</el-button>
                      <el-button @click="handleClose">取消</el-button>
                    </el-form-item>
                  </el-form>
                </template>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="平台管理员审核结果" placement="top" v-if="adminStatus === 1">
              <el-card>
                <h4><template v-if="manageStatus == 0"><span style="color:#BABABA;">待审核</span></template><template v-else-if="manageStatus == 1"><span style="color:#8BBFFD;">通过</span></template><template v-else><span style="color:#6F7073;">未通过</span></template></h4>
                <template v-if = "manageStatus > 0">
                  <p>备注: {{manageRemark}}</p>
                  <p>审核时间 {{manage_create_at}}</p>
                </template>
                <template v-else>
                  <el-form label-width="80px" style="margin-top: 20px" label-position="left" v-if="mode=== 'edit' && manageStatus === 0">
                    <el-form-item label="审核结果">
                      <el-select
                        v-model="editStatus">
                        <el-option :value="1" :label="'通过'"></el-option>
                        <el-option :value="2" :label="'未通过'"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="备注">
                      <el-input v-model="editRemark" placeholder="请输入备注信息"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-button type="primary" @click="submit">提交</el-button>
                      <el-button @click="handleClose">取消</el-button>
                    </el-form-item>
                  </el-form>
                </template>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="下线结果" placement="top" v-if="mode != 'edit' && info.status > 0">
              <el-card>
                <h4><template v-if="info.status == 1"><span style="color:#00aa00;">已下线</span></template><template v-else><span style="color:#aa0000;">未下线</span></template></h4>
              </el-card>
            </el-timeline-item>
          </template>
          <template v-else>
            <el-timeline-item timestamp="上线结果" placement="top" v-if="mode != 'edit' && info.status > 0">
              <el-card>
                <h4><template v-if="info.status == 1"><span style="color:#00aa00;">已上线</span></template><template v-else><span style="color:#aa0000;">未上线</span></template></h4>
              </el-card>
            </el-timeline-item>
          </template>
        </el-timeline>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "OfflineViewDialog",
  props: ["id", "visible", "mode"],
  data() {
    return {
      info: {},
      progress: [],
      fileList: [],
      adminStatus: null,
      adminRemark: null,
      admin_create_at: '',
      manage_create_at: '',
      manageStatus: null,
      manageRemark: null,
      progressStatus: 1,
      editStatus: 1,
      editRemark: ""
    }
  },
  watch: {
    id: {
      handler: function (val) {
        if (val === null) {
          return
        }
        this.progressStatus = 1
        this.adminStatus = null
        this.manageStatus = null
        this.getData()
      }
    }
  },
  methods: {
    getData() {
      this.fileList = []
      this.$get('admin/offline_apply/info/' + this.id).then(res => {
        this.info = res.data
        this.progress = res.list
        this.fileList = res.data.pics.split(',')
        const admin = this.progress.find(item => item.type === 1)
        const manage = this.progress.find(item => item.type === 2)
        if (admin !== undefined) {
          this.adminStatus = admin.status
          this.adminRemark = admin.remark
          this.admin_create_at = admin.create_at
        } else {
          this.adminStatus = 0
        }
        if (manage !== undefined) {
          this.manageStatus = manage.status
          this.manageRemark = manage.remark
          this.manage_create_at = manage.create_at
        } else {
          if (this.adminStatus === 2) {
            this.manageStatus = null
          } else {
            this.manageStatus = 0
          }
        }
      })
    },
    submit() {
      if (this.editRemark == "") {
        this.$message("备注不能为空")
        return
      }
      let formData = new URLSearchParams()
      formData.append('id', this.id)
      formData.append("remark", this.editRemark)
      formData.append("status", this.editStatus)
      this.$post('admin/exam_log/add', formData).then(res => {
        this.$message({
          type: 'success',
          message: "审核成功"
        })
        this.editStatus = 1
        this.editRemark = ""
        this.getData()
        this.$emit("handleClose")
      })
    },
    handleClose() {
      this.editStatus = 1
      this.editRemark = ""
      this.$emit("handleClose")
    }
  }
}
</script>
<style scoped lang="scss">

</style>
