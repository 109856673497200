<template>
  <div
    class="notification_dialog"
  >
    <div class="notification_dialog_content" ref="notificationDialog">
      <h2>一点一档</h2>
      <el-form>
        <!--        gutter表示每一栏之间的间距-->
        <el-row :gutter="20" >
          <el-col :span="10">
            <el-form-item label="餐饮企业名称：">
              <el-tooltip class="item" effect="dark" :content="showData.locale.Name" placement="top">
                <span
                  style="white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  max-width: 16em;
                  display: block;">
                  {{ customForm.localeName }}
                </span>
              </el-tooltip>
            </el-form-item>

          </el-col>
          <el-col :span="6" style="margin-top: 10px">
            <span>联系人：</span>
            <span v-if="customForm.customer.Contact">
                <span>{{ customForm.customer.Contact }}</span>
              </span>

          </el-col>
          <el-col :span="6">
            <el-form-item label="联系电话：">
              <span>{{ showData.Mobile }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" >
          <el-col :span="10">
            <el-form-item label="详细地址：">
              <el-tooltip class="item" effect="dark" :content="showData.locale.Addr" placement="top">
                <span
                  style="white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 17em;
                display: block;"
                >
                  {{ showData.locale.Addr }}
                </span>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="margin-top: 10px">
            <span>监测点类型：</span>
            <span>{{ showData.locale.Status | filter }}</span>

          </el-col>
          <el-col :span="6">
            <el-form-item label="所属单位:">
              <span
                style="white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 20em;
              display: block;"
              >
                  <el-tooltip class="item" effect="dark" :content="showData.locale.Owner" placement="top">
                    <span
                      style="white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 20em;
                      display: block;"
                    >
                      {{ showData.locale.Owner }}
                      </span>
                  </el-tooltip>

              </span>
              <!--              {{ showData.locale.Owner }}-->
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" >
          <el-col :span="10">
            <el-form-item label="菜系：">
              <span>{{ transformCuisine(showData.locale.Cuisine) }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="margin-top: 10px">
            <span>风速：</span>
            <span>{{ showData.locale.FanSpeed }} m/s</span>

          </el-col>
          <el-col :span="6">
            <el-form-item label="风量：">
              <span>{{ showData.locale.FanQuantity }} m³/h</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="管道截面面积：">
              <span>{{ showData.locale.PipeArea }} m²</span>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="margin-top: 10px">
            <span>灶头数量：</span>
            <span>{{ showData.locale.StoveNum }} 个</span>

          </el-col>
          <el-col :span="6">
            <el-form-item label="集气罩面积：">
              <span>{{ showData.locale.StoveArea }} m²</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <h4>一、餐饮企业在线监测情况汇总：</h4>
      <p>本餐饮场所基本信息及超标报警情况：</p>
      <div class="chart-title">日浓度曲线图</div>
      <div>
        <el-form :inline="true" size="mini">
          <el-form-item>
            <el-date-picker
              @change="getTenMinData"
              v-model="selDate"
              type="date"
              placeholder="日期选择"
            />
          </el-form-item>
        </el-form>
        <!-- <h5>{{ showData.beginDate }}至{{ showData.endDate }}</h5> -->
        <div class="chart-line">
          <e-charts :options="tenMinOption" autoresize />
        </div>
      </div>
      <div class="chart-title">历史浓度柱状图</div>
      <div>
        <el-form :inline="true" size="mini">
          <template>
            <el-form-item>
              <el-date-picker
                type="date"
                v-model="startDate"
                value-format="yyyy-MM-dd"
                placeholder="开始日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-date-picker
                type="date"
                v-model="endDate"
                value-format="yyyy-MM-dd"
                placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleDateRangeSelect()"
                >查询</el-button
              >
            </el-form-item>
          </template>
        </el-form>
        <template>
          <ve-histogram
            :data="showData.dataOption"
            :mark-line="showData.markLine"
            :extend="extend"
            :colors="['#00A1FF', '#F5B049', '#009999']"
            :settings="chartSettings"
            :grid="grid"
            :data-empty="showData.dataOption.rows.length === 0"
          />
        </template>
      </div>
      <h4>二、餐饮企业健康情况展示：</h4>
      <p></p>
      <div>
        <el-table
          :data="showData.tableData"
          border
          size="mini"
          style="width: 100%"
        >
          <el-table-column prop="localeName" label="监测点"></el-table-column>
          <el-table-column prop="mn" label="设备编号"></el-table-column>
          <el-table-column
            prop="complianceDays"
            label="达标天数"
          ></el-table-column>
          <el-table-column
            prop="excessDays"
            label="超标天数"
          ></el-table-column>
          <el-table-column
            prop="filterAbnormallyUsedDays"
            label="净化器不正常天数"
          ></el-table-column>
          <el-table-column
            prop="abnormalOfflineDays"
            label="店家原因离线天数"
          ></el-table-column>
          <el-table-column prop="healthCodeColor" label="油烟健康码">
            <template slot-scope="scope">
              <span class="iconfont" :class="scope.row.healthCodeColor"
                >&#xe704;</span
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <h4>三、现场照片：</h4>
      <p></p>
      <div>
        <el-alert
          v-if="fileList.length===0"
          center
          show-icon
          :closable="false"
          style="margin-bottom: 8px"
          title="未上传现场照片"
          type="info"
        ></el-alert>
        <el-image
          v-else
          style="width: 300px; height: 225px; margin: 8px;"
          v-for="(item, i) in fileList"
          :key="i"
          :src="item.url"
        >
          <div slot="error" class="image-slot">
            暂无图片
          </div>
        </el-image>
      </div>
<!---->
      <h4>四、上下线记录：</h4>
      <div style="margin-bottom: 17px">
        <el-table
          :data="record.list"
          border
          size="mini"
          style="width: 100%"
        >
          <el-table-column type="index" label="序号" align="center" width="55"/>
          <el-table-column align="center" prop="mn" label="设备编号"></el-table-column>
          <el-table-column
            align="center"
            prop="create_at"
            label="提交时间"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="user_name"
            label="提交人"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="reason"
            label="上下线原因"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="status_info"
            label="处理状态"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="handle_at"
            label="处理日期"
          ></el-table-column>
          <el-table-column
            align="center"
            label="操作">
            <template slot-scope="scope">
              <i
                title="查看"
                @click="handleView(scope.row)"
                class="opt el-icon-orange el-icon-view"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--      上下线记录查看弹窗组件-->
      <offline-view-dialog
        :id="id"
        :visible.sync="viewVisible"
        :mode="viewMode"
        @handleClose="handleViewClose"
      />
      <!--6月26日一电一档新增红码处理记录-->
      <h4>五、红码处理记录：</h4>
      <div style="margin-bottom: 17px">
        <el-table
          :data="redCode"
          border
          size="mini"
          style="width: 100%"
        >
          <el-table-column type="index" label="序号" align="center" width="55"/>
          <el-table-column align="center" prop="StartAt" label="起始日期" width="69" :formatter="dateFormat"></el-table-column>
          <el-table-column
            align="center"
            prop="ExcessDays"
            label="超标天数"
            width="69"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="FilterAbnormallyUsedDays"
            label="不正常使用净化器天数"

          >
            <template slot-scope="scope">
              <span>{{ scope.row.FilterAbnormallyUsedDays || "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="OfflineCausedByShopDays"
            label="店家原因离线天数"

          >
            <template slot-scope="scope">
              <span>{{ scope.row.OfflineCausedByShopDays || "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="VerifyAt"
            :formatter="dateFormat"
            label="核实日期"
            width="69"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="Verifier"
            label="核实人员"
            width="69"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="Cause"
            label="核实详情"
            width="69"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="HandleAt"
            :formatter="dateFormat"
            label="处理日期"
            width="69"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="Handler"
            label="处理人员"
            width="69"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="Resolution"
            label="处理措施"

          ></el-table-column>
          <el-table-column
            width="50"
            align="center"
            label="操作">
            <template slot-scope="scope">
              <i
                title="查看"
                @click="handleCodeView1(scope.row.Id, 'view')"
                class="opt el-icon-orange el-icon-view"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--      红码处理记录查看弹窗-->
      <management-view-dialog ref="managementViewDialog" @on-refresh="getHealthCodeRecode"  />

      <!--6月26日一电一档新增报警管理记录-->
      <h4>六、报警管理记录：</h4>
      <div style="margin-bottom: 17px">
        <el-table
          :data="alarmManagement.list"
          border
          size="mini"
          style="width: 100%"
        >
          <el-table-column type="index" label="序号" align="center" width="50"/>
          <el-table-column align="center" prop="acquit_at" label="报警时间" width="69"></el-table-column>
          <el-table-column
            align="center"
            prop="event_type"
            label="报警类型"
            width="69"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="event_status"
            label="事件状态"

          ></el-table-column>
          <el-table-column
            align="center"
            prop="process_at"
            label="核实时间"
            width="69"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="process_reason"
            label="报警原因"

          ></el-table-column>
          <el-table-column
            align="center"
            prop="process_info"
            label="核实结果"

          ></el-table-column>
          <el-table-column
            align="center"
            prop="rectify_desc"
            label="整改状态"
            width="69"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="rectify_message"
            label="整改情况"
            width="69"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="handle_at"
            label="执法处理时间"
            width="74"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="solution"
            label="执法处理情况"
            width="74"
          ></el-table-column>
          <el-table-column
            align="center"
            label="操作"
            width="55">
            <template slot-scope="scope">
              <i
                title="查看"
                @click="handleCodeView(scope.row.id, 'view')"
                class="opt el-icon-orange el-icon-view"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--      报警管理记录查看弹窗-->
      <alarm-view-dialog ref="alarmViewDialog" @on-refresh="getData"/>
      <!--      6月26日一电一档新增运维记录-->
      <h4>七、运维记录：</h4>
      <div style="margin-bottom: 17px">
        <el-table
          :data="operationalRecord.content"
          border
          size="mini"
          style="width: 100%"
        >
          <el-table-column type="index" label="序号" align="center" width="50"/>
          <el-table-column align="center" prop="CreateAt" :formatter="dateFormat" label="派单日期"width="69" ></el-table-column>
          <el-table-column
            align="center"
            prop="MN"
            label="维护设备"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="Typ"
            label="维护类型"
          >
            <template slot-scope="scope">
              <el-tag  v-if="scope.row.Typ === 1">维修</el-tag>
              <el-tag  v-else-if="scope.row.Typ === 2">移机</el-tag>
              <el-tag  v-else-if="scope.row.Typ === 3">拆机</el-tag>
              <el-tag  v-else-if="scope.row.Typ === 6">维保</el-tag>
              <el-tag  v-else-if="scope.row.Typ === 7">超标巡查</el-tag>
              <el-tag  v-else-if="scope.row.Typ === 8">异常离线巡查</el-tag>
              <el-tag  v-else-if="scope.row.Typ === 9">换机</el-tag>
              <el-tag  v-else>' '</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="MaintainerName"
            label="运维人员"
            width="69"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="MaintainAt"
            label="处理日期"
            :formatter="dateFormat"

          ></el-table-column>
          <el-table-column
            align="center"
            prop="Handler"
            label="处理人员"

          ></el-table-column>
          <el-table-column
            align="center"
            prop="Status"
            label="操作状态"
            width="69"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.Status === 1">已完成</div>
              <div v-else-if="scope.row.Status === 2">未完成</div>
              <div v-else-if="scope.row.Status === 3">待处理</div>
              <div v-else>未知状态</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="ExamStatus"
            label="审核状态"
            align="center"
            width="80"
            header-align="center"
          >
            <template slot-scope="scope">
              <el-tag type="warning" v-if="scope.row.ExamStatus === 0">待审核</el-tag>
              <el-tag type="success" v-else-if="scope.row.ExamStatus === 1">通过</el-tag>
              <el-tag type="danger" v-else-if="scope.row.ExamStatus === 2">拒绝</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="操作"
            width="55">
            <template slot-scope="scope">
              <i
                title="查看"
                @click="handleManagementLook(scope.row)"
                class="opt el-icon-orange el-icon-view"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--运维记录查看-->
      <el-dialog
        style="top:-100px;"
        width="550px"
        title="查看详情"
        :visible.sync="managementDialog.innerVisible"
        append-to-body>
        <el-form
          :model="managementDialog.innerData"
          size="mini"
          label-width="108px"
          label-position="left"
          :disabled="true"
        >
          <el-form-item prop="LocalAddr" label="所属单位">
            <el-input
              v-model="showData.locale.Owner"
              placeholder="无"
            >
            </el-input>
          </el-form-item>
          <el-row>
            <el-col>
              <el-form-item prop="LocaleName" label="监测点名称">
                <el-input
                  v-model="managementDialog.innerData.LocaleName"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item prop="LocalAddr" label="监测点地址">
            <el-input
              v-model="managementDialog.innerData.Addr"
              placeholder="无"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="Contact" label="联系人及电话">
            <el-input
              v-model="managementDialog.innerData.Contact"
              disabled
              placeholder="无"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="Mobile" label=" ">
            <el-input
              v-model="managementDialog.innerData.Mobile"
              disabled
              placeholder="无"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="DeviceId" label="维护设备">
            <el-input
              v-model="managementDialog.innerData.DeviceId"
            ></el-input>
          </el-form-item>
          <el-row>
            <el-form-item prop="Typ" label="维护类型">

              <span v-if="managementDialog.innerData.Typ === 1">维修</span>
              <span v-else-if="managementDialog.innerData.Typ === 2">移机</span>
              <span v-else-if="managementDialog.innerData.Typ === 3">拆机</span>
              <span v-else-if="managementDialog.innerData.Typ === 6">维保</span>
              <span v-else-if="managementDialog.innerData.Typ === 7">超标巡查</span>
              <span v-else-if="managementDialog.innerData.Typ === 8">异常离线巡查</span>
              <span v-else-if="managementDialog.innerData.Typ === 9">换机</span>
              <span v-else></span>

            </el-form-item>
          </el-row>
          <el-form-item prop="Remark" label="其他原因及目前状态备注">
            <el-input
              placeholder="其他原因及处理措施备注"
              type="textarea"
              :rows="2"
              autosize
              v-model="managementDialog.innerData.Remark"
            />
          </el-form-item>
          <el-form-item
            prop="Status"
            label="处理结果"
          >
            <!--            <el-input-->
            <!--              v-model="managementDialog.innerData.Status"-->
            <!--            ></el-input>-->
            <span v-if="managementDialog.innerData.Status === 1">已完成</span>
            <span v-else-if="managementDialog.innerData.Status === 2">待审核</span>
          </el-form-item>
          <el-form-item
            prop="Resolution"
            label="处理措施"
          >
            <el-input
              type="textarea"
              :rows="2"
              autosize
              v-model="managementDialog.innerData.Resolution"
            ></el-input>
          </el-form-item>
          <el-form-item prop="MaintainerName" label="运维人员">
            <el-input
              v-model="managementDialog.innerData.MaintainerName"
            ></el-input>
          </el-form-item>
          <el-form-item prop="CreateAt" label="派单日期">
            <el-date-picker
              disabled
              placeholder="派单日期"
              type="date"
              value-format="timestamp"
              format="yyyy-MM-dd"
              v-model="managementDialog.innerData.CreateAt"
            ></el-date-picker>
          </el-form-item>
          <el-form-item prop="CreateAt" label="处理日期">
            <el-date-picker
              placeholder="处理日期"
              type="date"
              value-format="timestamp"
              format="yyyy-MM-dd"
              v-model="managementDialog.innerData.MaintainAt"
            ></el-date-picker>
          </el-form-item>
          <el-form-item prop="Pos" label="定位">
            <baidu-map
              class="bm-view"
              :center="mapCenter"
              :zoom="mapZoom"
              ak="770b4c07458f53896ff0abd948755e20"
              @ready="handleMapReady"
            >
              <bm-geolocation
                anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                :showAddressBar="true"
                :autoLocation="true"
                @locationSuccess="getPosition"
              ></bm-geolocation>
            </baidu-map>
            <span>{{ managementDialog.innerData.Position ? managementDialog.innerData.Position : '暂无定位' }}</span>
          </el-form-item>
          <el-form-item label="图片">
            <el-image
              class="view-img"
              v-for="(item, i) in fileList1"
              :key="i"
              :src="item"
              :preview-src-list="fileList1"
            >
              <div slot="error" class="image-slot">
                暂无图片
              </div>
            </el-image>
          </el-form-item>
        </el-form>
      </el-dialog>

      <!--      6月26日一电一档新增净化设备清洗维保记录-->
      <h4>八、净化设备清洗维保记录：</h4>
      <div style="margin-bottom: 17px">
        <el-table
          :data="washData.content"
          border
          size="mini"
          style="width: 100%"
        >
          <el-table-column type="index" label="序号" align="center" />
          <el-table-column align="center" prop="LocaleName" label="监测点" ></el-table-column>
          <el-table-column
            align="center"
            prop="LocaleAddr"
            label="安装地址"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="CreatedAt"
            label="提交日期"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="BeginDate"
            label="起始日期"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="EndDate"
            label="终止日期"
          ></el-table-column>
          <el-table-column
            align="center"
            label="操作"
          >
            <template slot-scope="scope">
              <i
                title="查看"
                @click="lookWash(scope.row)"
                class="opt el-icon-orange el-icon-view"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-table-column
        prop="LocaleUploadedInfoPicS"
        label="清洗照片"
      >
        <template slot-scope="scope">
          <el-image
            style="width: 130px; height: 130px"
            v-for="(item,index) in scope.row.LocaleUploadedInfoPicS"
            :key=index
            :src=item
            fit='fill'
            :preview-src-list="scope.row.LocaleUploadedInfoPicS">
          </el-image>

        </template>
      </el-table-column>
      <!--设备清洗记录查看-->
      <el-dialog
        :title="dlg1.title"
        :visible.sync="dlg1.visible"
      >
        <el-form
          :model="formData"
          label-width="140px"
          ref="form"
          size="mini"

        >
          <el-form-item prop="LocaleId" label="监测点" style="margin-bottom: 30px">
            <el-select
              v-model="formData.LocaleName"
              placeholder="请选择监测点"
              filterable
              disabled
            >
            </el-select>
          </el-form-item>
          <el-form-item label="开始日期">
            <el-date-picker
              v-model="formData.BeginDate"
              value-format="yyyy-MM-dd"
              type="date"
              disabled
              style="margin-bottom: 30px"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="结束日期">
            <el-date-picker
              v-model="formData.EndDate"
              value-format="yyyy-MM-dd"
              type="date"
              disabled
              style="margin-bottom: 30px"
            ></el-date-picker>
          </el-form-item>
          <el-form-item prop="UploadType" label="类型">
            <el-select v-model="formData.UploadType" disabled style="margin-bottom: 30px">
              <el-option label="净化器清洗" value="FilterWash" ></el-option>
              <el-option
                label="歇业、停业申请"
                value="BusinessStopped"
              ></el-option>
              <el-option
                label="投诉"
                value="Complaint"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="Remark" label="备注">
            <el-input v-model.trim="formData.Remark" disabled style="margin-bottom: 30px"/>
          </el-form-item>
          <!--          <el-form-item>-->
          <!--            <el-upload-->
          <!--              accept=".jpg,.jpeg,.png"-->
          <!--              :show-file-list="false"-->
          <!--              :action="upload()"-->
          <!--              :on-success="handleAvatarSuccess"-->
          <!--              :limit="4"-->
          <!--            >-->
          <!--              <i class="el-icon-plus avatar-uploader-icon" />-->
          <!--              <div slot="tip">上传照片（最多4张，支持jpg、jpeg、png）</div>-->
          <!--            </el-upload>-->
          <!--          </el-form-item>-->
          <div class="img-list" v-if="fileList">
            <div
              class="single-img"
              v-for="(item, idx) in fileList"
              :key="idx"
              style="
              position: relative;
              display: inline-block;
              margin-right: 10px;
            "
            >
              <img :src="item" />
              <div class="remove-img el-icon-close" @click="removeImg(idx)"></div>
            </div>
          </div>
        </el-form>
      </el-dialog>

    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="downloadPdf" :loading="downloading" style="margin-left: 43%"
        >生成并下载pdf</el-button
      >
    </span>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import 'v-charts/lib/style.css'
import VeHistogram from 'v-charts/lib/histogram.common'
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/markLine'
import 'echarts/lib/component/legend'
import {
  dateFormater,
  getPname
} from '@/util/index'
import dayjs from 'dayjs'
import OfflineViewDialog from "@/component/offlineManagement/OfflineViewDialog.vue";
import AlarmViewDialog from "@/component/alarmManagement/AlarmViewDialog.vue";
import ManagementViewDialog from "@/component/healthCodeManagement/ManagementViewDialog.vue";

export default {
  props: ['device'],
  components: {ManagementViewDialog, AlarmViewDialog, OfflineViewDialog, VeHistogram, ECharts },
  data() {
    return {
      formData: {},
      dlg1: {
        visible: false,
        title: ''
      },//净化器设备清洗查看弹窗
      mapCenter: {lng: 120.306305, lat: 30.424877},
      mapZoom: 14,//运维记录的地图
      fileList1: [],
      managementDialog: {
        visible: false,
        innerVisible: false,
        loading: false,
        deviceData: null,
        maintenanceData: {},
        innerData: {}
      },//运维记录查看详情的弹窗
      operationalRecord:[],//运维记录
      alarmManagement:[],//报警管理记录
      HealthCodeRecordDialog: {//红码处理记录查看弹窗
        loading: false,
        visible: false,
        data: null
      },
      redCode:[],//红码处理记录
      viewVisible: false,//上下线记录查看弹窗
      id: null,
      viewMode: null,
      record:[],//新增上下线记录
      selDate: null,
      downloading: false, // 生成并下载pdf按钮 加载状态
      checkList: [],
      tableData: [],
      obj: {},
      cuisine: [],
      textarea01: '',
      textarea02: '',
      today: '',
      startDate: '',
      endDate: '',
      dataOption: {
        columns: [
          '采集时间',
          '油烟折算浓度',
          '颗粒物折算浓度',
          '非甲烷总烃折算浓度'
        ],
        rows: []
      },
      markLine: null,
      extend: {
        // series: {
        //   barWidth: 20
        // },
        'xAxis.0.axisLabel.rotate': 45
      },
      chartSettings: {
        metrics: ['油烟折算浓度', '颗粒物折算浓度', '非甲烷总烃折算浓度'],
        dimension: ['采集时间'],
        // xAxisType: 'time',
        yAxisName: ['单位:mg/m³']
      },
      grid: {
        show: true,
        top: 50,
        left: 10
        // backgroundColor: '#404a59'
      },
      chartLineSettings: {
        metrics: ['浓度曲线'],
        dimension: ['采集时间'],
        // xAxisType: 'time',
        yAxisName: ['平均浓度mg/m³']
      },
      tenMinOption: {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['油烟浓度', '颗粒物浓度', '非甲烷总烃浓度']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: [],
          boundaryGap: true,
          axisLabel: {
            interval: 17
          }
        },
        yAxis: {
          type: 'value',
          // min: 5,
          // data : [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4,5],
          name: '平均浓度mg/m³'
        },
        series: [
          {
            name: '油烟浓度',
            type: 'line',
            data: [],
            itemStyle: {
              normal: {
                color: '#00A1FF',
                lineStyle: {
                  type: 'solid',
                  color: '#00A1FF'
                }
              }
            },
            markLine: {
              symbol: 'none',
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: 'solid',
                    color: '#00A1FF'
                  },
                  label: {
                    position: 'start',
                    formatter: '{c} '
                  }
                }
              },
              data: [
                {
                  name: '阈值',
                  yAxis: 1
                }
              ]
            }
          },
          {
            name: '颗粒物浓度',
            type: 'line',
            data: [],
            itemStyle: {
              normal: {
                color: '#F5B049',
                lineStyle: {
                  type: 'solid',
                  color: '#F5B049'
                }
              }
            },
            markLine: {
              symbol: 'none',
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: 'solid',
                    color: '#F5B049'
                  },
                  label: {
                    position: 'start',
                    formatter: '{c} '
                  }
                }
              },
              data: [
                {
                  name: '阈值',
                  yAxis: 1
                }
              ]
            }
          },
          {
            name: '非甲烷总烃浓度',
            type: 'line',
            data: [],
            itemStyle: {
              normal: {
                color: '#009999',
                lineStyle: {
                  type: 'solid',
                  color: '#009999'
                }
              }
            },
            markLine: {
              symbol: 'none',
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: 'solid',
                    color: '#009999'
                  },
                  label: {
                    position: 'start',
                    formatter: '{c} '
                  }
                }
              },
              data: [
                {
                  name: '阈值',
                  yAxis: 1
                }
              ]
            }
          }
        ]
      },
      showData: {
        markLine: {
          symbol: 'none',
          itemStyle: {
            normal: {
              lineStyle: {
                type: 'solid'
                // color: "red",
              },
              label: {
                // show: false,
                position: 'start',
                formatter: '{c} '
              }
            }
          },
          data: []
        },
        dataOption: {
          columns: ['采集时间', '油烟折算浓度', '颗粒物折算浓度', '非甲烷总烃折算浓度'],
          rows: []
        },
        tableData: [],
        washData: []
      },
      fileList: [],
      customForm: null,//一点一档的开始数据
      localeForm: null
    }
  },
  watch: {
    device: {
      handler: function(v) {
        // 由于各组件命名不同，此处的Id为监测点Id(LocaleId)
        if (!v || !v.Id) return
        const data = v
        this.showData.Contact = v.CustomerContact
        this.showData.Mobile = v.CustomerMobile

        const nowDate = new Date()
        const now = nowDate.getTime()
        const last = now - 7 * 24 * 3600 * 1000
        const lastDate = new Date(last)
        let yy = lastDate.getFullYear()
        let mm = lastDate.getMonth() + 1
        let dd = lastDate.getDate()
        this.startDate = `${yy}-${mm}-${dd}`
        yy = nowDate.getFullYear()
        mm = nowDate.getMonth() + 1
        dd = nowDate.getDate()
        this.endDate = `${yy}-${mm}-${dd}`
        this.selDate = this.endDate
        const par = {
          LocaleId: data.Id,
          AcquitAtBegin: parseInt(last / 1000),
          AcquitAtEnd: parseInt(now / 1000)
        }
        this.customForm = {}
        this.$post('admin/listDataDetectorDailySumDaysByLocaleId', par)
          .then((res) => {
            this.customForm = res
            this.showData.locale = res.locale
            this.showData.locale.Owner = getPname(
              this.customerTree,
              this.showData.locale.Owner,
              'Org'
            ).join('/')
            this.handleDateRangeSelect()
          })
          .catch(() => {
          })

        // 近30日
        const last30 = now - 30 * 24 * 3600 * 1000
        const par30 = {
          LocaleId: data.Id,
          AcquitAtBegin: parseInt(last30 / 1000),
          AcquitAtEnd: parseInt(now / 1000)
        }
        const list = []
        this.$post('admin/listDataDetectorDailySumDaysByLocaleId', par30)
          .then((res) => {
            list.push(res)
            this.showData.tableData = list
          })
          .catch(() => {})

        //6月26新增上下线记录
        this.$get('admin/offline_apply',{"locale_id": v.Id}).then(res => {//{"locale_id":this.LocaleId}
          //console.log(res)
          //list.push(res)
          this.record = res
          //console.log("5月11新增上下线记录")
          //console.log(this.record)
        }).catch(() => {

        })
        //6月26新增红码处理记录
        const payload = {
          Param: {}
        }
        if (v.Id) {
          payload.Param["health_code_maintenance.locale_id"] = {S: 0, V: `'${v.Id}'`}
        }
        this.$post('admin/listHealthCodeRecord',payload).then((res) => {
          //list.push(res)
          //console.log("5月13新增红码处理记录")
          this.redCode = res.content
          //console.log(this.redCode)
        }).catch(() => {

        })
        //6月26新增报警管理记录
        this.$get("admin/alarmManagement/list",{"owner":v.Owner}).then(res => {
          //list.push(res)
          this.alarmManagement = res
          //console.log("5月13新增报警管理记录")
          //console.log(this.alarmManagement)
        }).catch(() => {

        })
        //6月26新增运维记录
        const operational = {
          FormType: 1,
          Param: {}
        }
        if (v.Owner) {
          operational.Param['maintenance.owner'] = {S: 4, V: `${v.Owner}`}
        }
        this.$post('admin/listMaintenance', operational).then((res) => {
          //list.push(res)
          this.operationalRecord = res
          //console.log("5月13新增运维记录")
          //console.log(this.operationalRecord)
        }).catch(() => {

        })

        this.getTenMinData()

        //获取净化设备清洗维保记录（从在线监管-监管地图中获取的是LocaleName）
        this.$post('admin/queryLocaleUploadedInfo', {"LocaleName": v.LocaleName}).then((res) => {
          //list.push(res)
          this.washData = res
          //console.log("5月13净化设备清洗维保记录")
          //console.log(this.washData)
        }).catch(() => {
        })
        //获取净化设备清洗维保记录（从在线监管-健康码管理获取的是Name）
        if (v.LocaleName === undefined){
          //获取净化设备清洗维保记录（从在线监管-监管地图中获取的是LocaleName）
          this.$post('admin/queryLocaleUploadedInfo', {"LocaleName": v.Name}).then((res) => {
            //list.push(res)
            this.washData = res
            //console.log("5月13净化设备清洗维保记录")
            //console.log(this.washData)
          }).catch(() => {
          })
        }

        this.fileList = []
        this.localeForm = {}
        const param = { Param: { id: data.Id }}
        this.$post('admin/listLocale', param)
          .then((res) => {
            const urls = res.content[0].LocalePicS || []
            urls.forEach((item) => {
              this.fileList.push({ url: item })
            })

            this.localeForm = res.content[0]
            if (res.content[0].Owner) {
              this.localeForm.Owner = getPname(
                this.customerTree,
                res.content[0].Owner,
                'Org'
              ).join('/')
            }
          })
          .catch(() => {})
      },
      immediate: true
    }
  },
  filters: {
    filter(type) {
      const op = {
        1: '一般监测点',
        2: '特殊监测点',
        99: '废弃监测点'
      }
      return op[type]
    }
  },
  computed: {
    ...mapState(['customerTree'])
  },
  created() {
    this.getxDates(31)

    this.$get('admin/listAllCuisine').then((res) => {
      this.cuisine = res
    })
  },
  mounted() {
    // this.getData()
    // console.log(this.lineData)
  },
  methods: {
    removeImg(idx) {
      //   this.changeState = true;
      this.fileList.splice(idx, 1)
    },
    //查看清洗记录
    lookWash:function(scope){
      // this.washDialog = true
      this.dlg1.title = '查看'
      this.dlg1.visible = true
      this.fileList = scope.LocaleUploadedInfoPicS
      this.formData = Object.assign({}, scope)
    },
    //查看运维记录弹窗
    handleManagementLook(row) {
      this.managementDialog.innerVisible = true
      this.managementDialog.innerData = Object.assign({}, row, {
        MaintenancePics: []
      })
      this.fileList1 = row.MaintenancePicS//this.fileList1是存储的图片的网址
    },
    //运维记录地图加载
    handleMapReady() {
      if (this.managementDialog.innerVisible.Lng && this.managementDialog.innerVisible.Lat) {
        this.mapCenter = {lng: this.managementDialog.innerVisible.Lng, lat: this.managementDialog.innerVisible.Lat}
      }
    },
    //运维记录地图定位
    getPosition(pos) {
      this.managementDialog.innerVisible.Lng = pos.point.lng.toString()
      this.managementDialog.innerVisible.Lat = pos.point.lat.toString()
      this.managementDialog.innerVisible.Position = pos.addressComponent['province'] + pos.addressComponent['city'] +
        pos.addressComponent['district'] + pos.addressComponent['street'] +
        pos.addressComponent['streetNumber']
    },
    //报警管理记录查看弹窗
    getData() {
      this.loading = true
      let param = Object.assign({}, this.filter)
      if (param.owner.length !== 0) {
        let userInfo = getUserInfo().Info
        param.owner = ownerJoin(param.owner, userInfo)
      }

      if (param.start_time) {
        param.end_time = dayjs(param.start_time).add(1, 'day').unix()
        param.start_time = dayjs(param.start_time).unix()
      }

      this.$get('admin/offline_apply', param).then(res => {
        this.tableData = res.list
        this.total = res.count
        this.loading = false
      })
    },
    //报警管理查看弹窗
    handleCodeView(id, mode) {
      this.$refs['alarmViewDialog'].dealDialog(mode, { id: id })
    },
    //红码处理记录查看弹窗
    handleCodeView1(id, mode) {
      this.$refs['managementViewDialog'].dealDialog(mode, { id: id })
    },
    getHealthCodeRecode() {
      this.HealthCodeRecordDialog.loading = true
      this.HealthCodeRecordDialog.visible = true
      const payload = {
        Param: {}
      }
      if (this.healthCodeInfo.LocaleId) {
        payload.Param['health_code_maintenance.locale_id'] = {S: 0, V: `'${this.healthCodeInfo.LocaleId}'`}
      }
      this.$post('admin/listHealthCodeRecord', payload).then(res => {
        this.HealthCodeRecordDialog.data = res.content
        this.HealthCodeRecordDialog.loading = false
      })
    },
    // 用于将时间戳转换为YY-MM-DD
    dateFormat(r, c) {
      return dateFormater(r[c.property])
    },
    // 一点一档上下线记录操作查看的弹窗
    handleView(row) {
      this.id = row.id
      this.viewVisible = true
      this.viewMode = 'view'
    },
    // 关闭一点一档上下线记录操作查看的弹窗
    handleViewClose() {
      this.viewVisible = false
      this.getData()
    },

    /**
     * @description 生成并下载pdf
     */
    downloadPdf() {
      this.downloading = true
      this.ExportSavePdf(this.$refs.notificationDialog, '一点一档').then(
        () => {
          setTimeout(() => {
            this.downloading = false
            this.handleClose()
          }, 500)
        }
      )
    },

    /**
     * @description 关闭对话框
     */
    handleClose() {
      this.$emit('on-close', false)
    },
    // getData(){
    //   console.log(this.showData,111111)
    //   const par =  { LocaleId: this.showData.LocaleId }
    //   this.$post('admin/listDataDetectorDailySumDaysByLocaleId', par).then((res) => {
    //     this.tableData = res.content
    //   }).catch(() => {})
    // },
    getxDates(n) {
      const day = new Date()
      const yy = day.getFullYear()
      const mm = day.getMonth() + 1
      const dd = day.getDate()
      this.today = `${yy}年${mm}月${dd}日`
    },
    getTenMinData() {
      let tpl, url
      tpl = {
        mn: this.device.MN,
        at: dayjs(this.selDate).unix()
      }
      url = 'admin/listTenMinData'
      this.$post(url, tpl).then((response) => {
        this.tenMinOption.series[0]['data'] = []
        this.tenMinOption.series[0]['markLine']['data'][0]['yAxis'] = this.device.EmissionsSill || 0
        this.tenMinOption.xAxis['data'] = []
        this.tenMinOption.xAxis['axisLabel']['interval'] = 143
        this.tenMinOption.series[1]['data'] = []
        this.tenMinOption.series[1]['markLine']['data'][0]['yAxis'] = this.device.GranuleSill || 0
        this.tenMinOption.series[2]['data'] = []
        this.tenMinOption.series[2]['markLine']['data'][0]['yAxis'] = this.device.HydrocarbonSill || 0
        if (response && response.length !== 0) {
          if (this.selDate === null) {
            this.selDate = new Date(
              new Date(res[0]['acquit_at'] * 600 * 1000).setHours(0, 0, 0, 0)
            ).getTime()
          }
          const t = new Date(this.selDate)
          t.setHours(0, 0, 0, 0)
          const endTime = Math.floor(t.getTime() / 600000) + 143
          for (var i = Math.floor(t.getTime() / 600000); i <= endTime; i++) {
            let obj = {
              emissions_conc: 0,
              granule_conc: 0,
              hydrocarbon_conc: 0,
              counter: 1
            }
            response.forEach(e => {
              if (e.acquit_at === i) {
                obj = e
              }
            })
            this.tenMinOption.series[0]['data'].push(
              (obj.emissions_conc ).toFixed(2)
            )
            this.tenMinOption.series[1]['data'].push(
              (obj.granule_conc).toFixed(2)
            )
            this.tenMinOption.series[2]['data'].push(
              (obj.hydrocarbon_conc).toFixed(2)
            )
            this.tenMinOption.xAxis['data'].push(dateFormater(i * 600, true, false))
          }
          this.$forceUpdate()
        }
      })
    },
    handleDateRangeSelect() {
      // const tpl = { mn: '010000A8900016F018010029' };
      const tpl = { localeId: this.device.Id }
      tpl['AcquitAtBegin'] = parseInt(new Date(this.startDate + ' 00:00:00').getTime() / 1000)
      tpl['AcquitAtEnd'] = parseInt(new Date(this.endDate + ' 23:59:59').getTime() / 1000)
      this.$post('admin/queryDataDetectorDaily', tpl).then((res) => {
        this.showData = Object.assign(
          {},
          this.showData,
          this.customForm,
          this.localeForm
        )
        this.showData.LocaleId = tpl.localeId
        this.showData.beginDate = dateFormater(
          tpl['AcquitAtBegin'],
          false
        )
        this.showData.endDate = dateFormater(
          tpl['AcquitAtEnd'],
          false
        )
        let data
        this.showData.dataOption.rows = []
        for (var i = tpl['AcquitAtBegin']; i <= tpl['AcquitAtEnd']; i += 86400) {
          data = res.find((e) => dateFormater(e.AcquitAt, false) === dateFormater(i, false))
          this.showData.dataOption.rows.push({
            采集时间: dateFormater(i, false),
            油烟折算浓度: data ? data.CEmissions : 0,
            颗粒物折算浓度: data ? data.CGranule : 0,
            非甲烷总烃折算浓度: data ? data.CHydrocarbon : 0
          })
        }
        this.showData.markLine.data.push({
          lineStyle: {
            color: '#00A1FF'
          },
          yAxis: res[0].LocaleEmissionsSill || 0
        }, {
          lineStyle: {
            color: '#F5B049'
          },
          yAxis: res[0].LocaleGranuleSill || 0
        }, {
          lineStyle: {
            color: '#009999'
          },
          yAxis: res[0].LocaleHydrocarbonSill || 0
        })
        this.$forceUpdate()
      })
    },
    transformCuisine(k) {
      for (let i = 0, len = this.cuisine.length; i < len; i++) {
        if (this.cuisine[i].Id === k) {
          return this.cuisine[i].Name
        }
      }
      return ''
    }
  }
}
</script>
<style lang="scss" scoped>
.checkbox_group_box {
  font-size: inherit;
  .el-checkbox {
    display: block;
  }
}
.notification_dialog {
  .notification_dialog_content {
    font-size: 13px;
    .el-form-item {
      margin-bottom: 0;
      .el-form-item__content {
        span {
          line-height: 40px;
        }
      }
    }
    h2 {
      text-align: center;
      font-size: 28px;
    }
    h4 {
      font-size: 20px;
      //margin: 16px 0 12px;
      margin-left: 38px;
      margin-bottom: 17px;
    }

    h5 {
      font-size: 13px;
      margin: 5px 0;
    }

    p {
      margin: 5px 0;
    }

    .date_box {
      text-align: right;
      padding: 10px 0;
      margin-top: 20px;
      font-weight: bold;
      font-weight: 15px;
    }

    .chart-title {
      font-size: 16px;
      font-weight: bold;
      margin: 12px auto;
      text-align: center;
    }

    .one-third {
      width: 33%;
    }
  }
}
</style>
<style>
.echarts {
  width: 100% !important;
}
</style>
