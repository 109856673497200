<template>
  <div>
    <header v-if="loading">加载中......</header>
    <main v-if="!loading && type === 1">
      <over-time-dialog
        :localeId="paraForAlarmMsg.Param.localeId"
        :owner="paraForAlarmMsg.Param.owner"
      ></over-time-dialog>
<!--      <el-pagination-->
<!--        style="margin: 16px 0;text-align: center"-->
<!--        :current-page.sync ="currentPage"-->
<!--        :page-size="paraForAlarmMsg.Size"-->
<!--        layout="prev, pager, next"-->
<!--        :total="alarmData.total">-->
<!--      </el-pagination>-->
<!--      <el-table-->
<!--        v-loading="innerloading"-->
<!--        :data="alarmData.content"-->
<!--        style="width:100%;"-->
<!--      >-->
<!--        <el-table-column-->
<!--          type="index"-->
<!--          width="60"-->
<!--        ></el-table-column>-->
<!--        <el-table-column-->
<!--          prop="Id"-->
<!--          label="监测点"-->
<!--          width="220">-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--          prop="MN"-->
<!--          label="设备"-->
<!--          width="220">-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--          label="类型"-->
<!--          width="100">-->
<!--          <template slot-scope="scope">-->
<!--            <span>{{ msgTypeFormatter(scope.row.MsgType) }}</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--          prop="Addr"-->
<!--          label="地址"-->
<!--          width="220">-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--          label="报警日期"-->
<!--          width="180">-->
<!--          <template slot-scope="scope">-->
<!--            <span>{{ new Date(scope.row.AcquitAt * 1000).toLocaleString('zh-CN', { hour12: false }) }}</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--          prop="Content"-->
<!--          label="内容">-->
<!--        </el-table-column>-->
<!--      </el-table>-->
<!--      <el-pagination-->
<!--        style="margin: 16px 0;text-align: center"-->
<!--        :current-page.sync="currentPage"-->
<!--        :page-size="paraForAlarmMsg.Size"-->
<!--        layout="prev, pager, next"-->
<!--        :total="alarmData.total">-->
<!--      </el-pagination>-->
    </main>
    <main v-if="!loading && type === 0">
      <notification7-dialog :device="device"></notification7-dialog>
    </main>
  </div>
</template>

<script>
import notification7Dialog from '@/component/Notification7Dialog'
import overTimeDialog from '@/component/overTimeDialog'

export default {
  name: 'NotificationForBigscreen',
  data() {
    return {
      device: null,
      loading: true,
      innerloading: true,
      type: null,
      paraForAlarmMsg: {
        StartAt: 0,
        Size: 20,
        Param: {
          localeId: null,
          owner: null
        }
      },
      alarmData: null,
      currentPage: 1,
      paraForCurAlarm: {
        StartAt: 0,
        Size: 20,
        Typ: 2,
        SortBy: 'status',
        SortMode: 'asc',
        Param: {
          mn: null,
          LocaleWithDevice: true
        }
      }
    }
  },
  mounted() {
    this.loading = true
    this.innerloading = true
    this.type = parseInt(this.$route.query.type)
    if (this.type === 0) {
      this.paraForCurAlarm.Param.mn = this.$route.query.MN
      this.getCurAlarm()
    } else {
      this.paraForAlarmMsg.Param.localeId = this.$route.query.LocaleId
      this.paraForAlarmMsg.Param.owner = this.$route.query.Owner
      this.loading = false
    }
  },
  methods: {
    getCurAlarm() {
      this.$post('admin/listCurAlarm', this.paraForCurAlarm).then((res) => {
        this.device = {
          ...res.content[0],
          Id: res.content[0].LocaleId
        }
        this.loading = false
        this.innerloading = false
      })
    },
    getAlarmMsg() {
      const param = this.paraForAlarmMsg
      param.StartAt = (this.currentPage - 1) * this.paraForAlarmMsg.Size
      this.$post('admin/listAlarmMsg', param).then((res) => {
        this.alarmData = res
        this.loading = false
        this.innerloading = false
      })
    },
    msgTypeFormatter(type) {
      switch (type) {
        case 'ExceedStandard':
          return '超标报警'
        case 'AbnormalOffline':
          return '异常离线'
        default:
          return ''
      }
    }
  },
  components: {
    notification7Dialog,
    overTimeDialog
  },
  watch: {
    'currentPage': {
      handler: function(val) {
        this.innerloading = true
        this.getAlarmMsg()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  main {
    width: 80%;
    margin: 0 auto;
    font-size: 13px;
    span {
      line-height: 40px;
    }
    h2 {
      text-align: center;
    }
    h4 {
      font-size: 16px;
      margin: 16px 0 12px;
    }

    h5 {
      font-size: 13px;
      margin: 5px 0;
    }

    p {
      margin: 5px 0;
    }
  }
</style>
